// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-accommodation-accommodation-js": () => import("./../../../src/templates/accommodation/accommodation.js" /* webpackChunkName: "component---src-templates-accommodation-accommodation-js" */),
  "component---src-templates-availability-availability-js": () => import("./../../../src/templates/availability/availability.js" /* webpackChunkName: "component---src-templates-availability-availability-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/contact/contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-policies-cookie-js": () => import("./../../../src/templates/policies/cookie.js" /* webpackChunkName: "component---src-templates-policies-cookie-js" */),
  "component---src-templates-policies-privacy-js": () => import("./../../../src/templates/policies/privacy.js" /* webpackChunkName: "component---src-templates-policies-privacy-js" */),
  "component---src-templates-policies-tandc-js": () => import("./../../../src/templates/policies/tandc.js" /* webpackChunkName: "component---src-templates-policies-tandc-js" */)
}

