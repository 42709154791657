import './src/styles/global.css';
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
const SITE_KEY = "6Lf9434aAAAAACHVh-vSeZprWCM0aXEz7jlp3NVw";
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
            {element}
        </GoogleReCaptchaProvider>
    )
}